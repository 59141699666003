import React from 'react'
import './Header.css';
import Logo from '../Logo/Logo';
import SocialContainer from '../SocialContainer/SocialContainer';

const Header = ({setModalVisibility, setModalType}) => {
    return (
        <div className='headerContainer'>
          <Logo/>
          <SocialContainer setModalVisibility={setModalVisibility} setModalType={setModalType}/>
        </div>
          
      )
}

export default Header