import React, {useState } from 'react'
import './HomeScreen.css';
import Slideshow from '../../Components/Slideshow/Slideshow';
import Header from '../../Components/Header/Header';
import Modal from '../../Components/Modal/Modal';

function HomeScreen() {
  const [modalVisibility, setModalVisibility] = useState('none')
  const [modalType, setModalType] = useState('')
  return (
    <div className="home">
      <Header 
      setModalVisibility={setModalVisibility}
      setModalType={setModalType}
      />
        <div className="homeBody">
          <Slideshow/>
          <Modal 
          modalVisibility= {modalVisibility} 
          setModalVisibility={setModalVisibility}
          modalType={modalType}
          />
      </div>
    </div>
  );
}

export default HomeScreen;
