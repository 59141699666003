import React from 'react'
import './Logo.css';

const Logo = () => {
    return (      
        <div className='logo'>
        <a href="">
            <img src='TFLogo.png' alt='logo'></img>   
        </a>
        </div>
    )
}

export default Logo