import React from 'react'
import './Modal.css'
import ModalAboutUs from '../ModalAboutUs/ModalAboutUs'
import ModalContact from '../ModalContact/ModalContact'


const Modal = ({modalVisibility, setModalVisibility, modalType}) => {
    return (
        <div className='modalContainer'>
            <div className='modal' style={{display: modalVisibility}}>
                <div className='modalCrossContainer'>
                    <div className='modalCross' onClick={()=> setModalVisibility('none')}>&#x2a2f;</div>
                </div>
                {modalType === 'AboutUs' && <div>
                    <ModalAboutUs/>
                </div>}
                {modalType === 'Contact' && <div>
                    <ModalContact/>
                </div>}
            </div> 
        </div>    
    )
}

export default Modal