import React, {useState } from 'react'
import './Slideshow.css'
import slideData from '../../slideData'
import { useTransition, animated, easings } from '@react-spring/web'

const Slideshow = () => {
const [index, setIndex] = useState(0)
const transitions = useTransition(index, {
    key: index,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 4000, easing: easings.easeOutSine },
    onRest: (_a, _b, item) => {
      if (index === item) {
        setIndex(state => (state + 1) % slideData.length)
      }
    }
  })
  return (
    <div>
      {transitions((style, i) => (
        <animated.div
          className='bg'
          style={{
            ...style,
            backgroundImage: `url(${slideData[i].img})`,
          }}
        />
      ))}
      <div className='textContainer'>
      {transitions((style, i) => (
        <animated.div
          className='txt'
          style={{
            ...style,
            backgroundImage: `url(${slideData[i].txt})`,
          }}
        />
      ))}
      </div>
    </div>
  )
}


export default Slideshow