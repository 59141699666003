import React from 'react'
import './ModalContact.css'
import Map from '../Map/Map'

const ModalContact = (props) => {
    return (
        <div>
            <h1>Contact</h1>
        <article className='contact'>
            <p>
                <a href="mailto:info@tenfeet.eu">Email: info@tenfeet.eu</a><br></br>
                Phone: +31 (0)30 261 7443
            </p>
            <p>
                <a href='https://www.google.nl/maps/place/Tenfeet/@52.1082084,5.0470851,17z/data=!3m1!4b1!4m6!3m5!1s0x47c66fc3371514c1:0x55cfe3ae779122c1!8m2!3d52.1082084!4d5.0470851!16s%2Fg%2F1tksg_x2'
                >Adress: Krommewetering 21<br></br> 
                3543AP - Utrecht<br></br>
                the Netherlands</a>
            </p>
            <p>
                VAT: NL8134.25.499.B01 <br></br>
                IBAN: NL17ABNA0617499829 <br></br>
                BIC: ABNANL2A <br></br>
            </p>
            <div className='termsButton'><a href='https://tenfeet.eu/av.pdf'>Terms of Service</a></div>
        </article>   
        <Map/>
        </div>
    )
}

export default ModalContact