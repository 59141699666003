import React from 'react'
import './Social.css';

const Social = (props) => {
    return (    
        <div className='social' onClick={()=>{ 
                if (props.setModalVisibility)
                {props.setModalVisibility('block'); props.setModalType(props.name)}
                }
            }> 
            <a href={props.url} target="_blank" rel='noreferrer'>
              <img className='image' src={props.img} alt={props.name}></img>   
              <img className='image-hover' src={props.imgHover} alt={props.name}></img>   
            </a>
        </div> 
    )
}

export default Social