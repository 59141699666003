import React from 'react'
import './Map.css'

const Map = () => {
    return (
        <iframe
            title='tfMap'
            frameBorder="0"
            referrerPolicy="no-referrer-when-downgrade"
            src={process.env.REACT_APP_MAP_API_URL}
            allowFullScreen>
        </iframe>
    )
}

export default Map