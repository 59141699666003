const slideData = [
    {img: '1.Programming1.jpg', txt: 'TEXT1_PROG.png'},
    {img: '1.Programming2.jpg', txt: ''},
    {img: '2.Theatre1.jpg', txt: 'TEXT2_THEA.png'},
    {img: '2.Theatre2.jpg', txt: ''},
    {img: '3.LiveEvents1.jpg', txt: 'TEXT3_LIVE.png'},
    {img: '3.LiveEvents2.jpg', txt: ''},
    {img: '4.Festivals1.jpg', txt: 'TEXT4_FEST.png'},
    {img: '4.Festivals2.jpg', txt: ''},
    {img: '5.Design1.jpg', txt: 'TEXT5_DSGN.png'},
    {img: '5.Design2.jpg', txt: ''},
    {img: '6.TV1.jpg', txt: 'TEXT6_TV.png'},
    {img: '6.TV2.jpg', txt: ''},
]

export default slideData;