import React from 'react'
import './SocialContainer.css';
import Social from '../Social/Social';


const SocialContainer = ({setModalVisibility, setModalType}) => {
    return (
        <div className='socialContainer'>
            <Social name="Facebook" img="TFWFB.png" imgHover= "TFWFB_red.png" url= "https://www.facebook.com/tenfeet.group/"/>
            <Social name="Instagram" img="TFWIN.png" imgHover= "TFWIN_red.png" url= "https://www.instagram.com/tenfeet_bv/?hl=nl"/>
            <Social name="Youtube" img="TFWYT.png" imgHover= "TFWYT_red.png" url= "https://www.youtube.com/user/tenfeetviz"/>
            <Social name="Contact" img="TFWAB.png" imgHover= "TFWAB_red.png" setModalVisibility={setModalVisibility} setModalType={setModalType}/>
            <Social name="AboutUs" img="TFWTF.png" imgHover= "TFWTF_red.png" setModalVisibility={setModalVisibility} setModalType={setModalType}/>  
        </div> 
    )
}

export default SocialContainer