import React from 'react'
import './ModalAboutUs.css'

const ModalAboutUs = (props) => {
    return (
        <article className='aboutUs'>
            <h1>This is tenfeet</h1>
            <h3>A to Z:</h3>
            <p>
                The full package when you need it. 
                A good team of creative, experienced experts, focussed on solutions.
                From concept in design, pre-programming in our studios, to playback during the event using in-house available hardware for showcontrol. Ease of mind from A to Z.
            </p>
            <h3>Programming:</h3>
            <p>
                Creative minds think alike. Tenfeet provides experts on a variety of consoles and mediaservers to efficiently program the light and/or video cuestacks or timelines for your show or event. 
                Suitable sparring partners to give input to creative and technical challenges are proven to be valuable to take your production to a higher level. 
            </p>
            <h3>3D:</h3>
            <p>
                tenfeet services your production using several professional CAD and 3D modelling software applications, installed on up-to-date computer hardware.
                Think of 3D impressions for your concept-design, 3D models to use for video applications or high-detail pre-visualization files to use to program lights for your show, including set-changes, automation and video-inputs.. Also mobile
            </p>
            <h3>Hardware:</h3>
            <p>
                What qualifies a smooth production? Experienced professionals on the job, but also reliable hardware to work as expected. 
                Tenfeet offers hardware for creative show-control and playback to suit the needs  for your production or tour. 
                Also consult us to get hardware for special control applications or timecode conversion products. 
            </p>
            <h3>Training:</h3>
            <p>
                Tenfeet offers workshop-style training courses using field experience. 
                Training-days as spent efficiently, we specify topics to the needs of the trainee. 
                Training is possible in many fields like console or server-programming, programming methods in general, timecode implementation, 3D software, ect.  
                at the desired customised training-level
            </p>
        </article>   
    )
}

export default ModalAboutUs